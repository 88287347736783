@primary: #15233c;
@secondary: #e3e5e8;
@red: #e54b6a;
@white: #f5f6fa;

body {
  background-color: @white !important;
}

.MuiCollapse-entered {
  min-height: auto !important;
}

// Todo use package style

// ---------------------------------
//    Start Ingredient table
// ---------------------------------

/* Collection default theme */

.ReactVirtualized__Collection {
}

.ReactVirtualized__Collection__innerScrollContainer {
}

/* Grid default theme */

.ReactVirtualized__Grid {
}

.ReactVirtualized__Grid__innerScrollContainer {
}

/* Table default theme */

.ReactVirtualized__Table {
}

.ReactVirtualized__Table__Grid {
}

.ReactVirtualized__Table__headerRow {
  font-weight: 700;
  text-transform: uppercase;
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  flex-direction: row;
  -webkit-box-align: center;
  align-items: center;
}
.ReactVirtualized__Table__row {
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  flex-direction: row;
  -webkit-box-align: center;
  align-items: center;
}

.ReactVirtualized__Table__headerTruncatedText {
  display: inline-block;
  max-width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.ReactVirtualized__Table__headerColumn,
.ReactVirtualized__Table__rowColumn {
  margin-right: 10px;
  min-width: 0px;
}
.ReactVirtualized__Table__rowColumn {
  text-overflow: ellipsis;
  white-space: nowrap;
}

.ReactVirtualized__Table__headerColumn:first-of-type,
.ReactVirtualized__Table__rowColumn:first-of-type {
  margin-left: 10px;
}
.ReactVirtualized__Table__sortableHeaderColumn {
  cursor: pointer;
}

.ReactVirtualized__Table__sortableHeaderIconContainer {
  display: -webkit-box;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
}
.ReactVirtualized__Table__sortableHeaderIcon {
  -webkit-box-flex: 0;
  flex: 0 0 24px;
  height: 1em;
  width: 1em;
  fill: currentColor;
}

/* List default theme */

.ReactVirtualized__List {
}
.rst__rowWrapper {
  padding: 2px 2px 2px 0;
  height: 100%;
  box-sizing: border-box;
  width: 100%;
}

.rst__rtl.rst__rowWrapper {
  padding: 2px 0 2px 2px;
}

.rst__row {
  height: 100%;
  white-space: nowrap;
  display: flex;
  flex-direction: row-reverse;
}
.rst__row > * {
  box-sizing: border-box;
}

/**
 * The outline of where the element will go if dropped, displayed while dragging
 */
.rst__rowLandingPad,
.rst__rowCancelPad {
  border: none !important;
  box-shadow: none !important;
  outline: none !important;
}
.rst__rowLandingPad > *,
.rst__rowCancelPad > * {
  opacity: 0 !important;
}
.rst__rowLandingPad::before,
.rst__rowCancelPad::before {
  background-color: lightblue;
  border: 3px dashed white;
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: -1;
}

/**
 * Alternate appearance of the landing pad when the dragged location is invalid
 */
.rst__rowCancelPad::before {
  background-color: #e6a8ad;
}

/**
 * Nodes matching the search conditions are highlighted
 */
.rst__rowSearchMatch {
  outline: solid 3px #0080ff;
}

/**
 * The node that matches the search conditions and is currently focused
 */
.rst__rowSearchFocus {
  outline: solid 3px #fc6421;
}

.rst__rowContents,
.rst__rowLabel,
.rst__rowToolbar,
.rst__moveHandle,
.rst__toolbarButton {
  display: inline-block;
  vertical-align: middle;
}

.rst__rowContents {
  position: relative;
  height: 100%;
  border: 0;
  border-left: none;
  // box-shadow: 0 2px 2px -2px;
  padding: 0 0 0 0;
  border-radius: 2px;
  min-width: 230px;
  flex: 1 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: white;
}

.rst__rtl.rst__rowContents {
  border-right: none;
  border-left: solid #bbb 1px;
  padding: 0 0 0 0;
}

.rst__rowContentsDragDisabled {
  border-left: solid #bbb 1px;
}

.rst__rtl.rst__rowContentsDragDisabled {
  border-right: solid #bbb 1px;
  border-left: solid #bbb 1px;
}

.rst__rowLabel {
  flex: 0 1 auto;
  padding-right: 0px;
  width: 100%;
}
.rst__rtl.rst__rowLabel {
  padding-left: 0px;
  padding-right: inherit;
}

.rst__rowToolbar {
  flex: 0 1 auto;
  display: flex;
}

.SortableTree-header {
  position: sticky;
  top: 0;
  transform: translateY(64px);
  margin-top: -64px;
  margin-bottom: 64px;
  z-index: 10;
}

.rst__moveHandle,
.rst__loadingHandle {
  height: 15px;
  width: 15px;
  background: #d9d9d9
    url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='18' height='18' fill='none'%3E%3Cpath fill='%23686868' d='M11.46 4.52a.56.56 0 0 1-.8 0l-1.1-1.1v2.77a.56.56 0 1 1-1.12 0V3.42l-1.1 1.1a.56.56 0 0 1-.8-.8L8.6 1.67a.56.56 0 0 1 .8 0l2.06 2.07a.56.56 0 0 1 0 .8ZM4.52 10.66a.56.56 0 1 1-.8.8L1.67 9.4a.56.56 0 0 1 0-.8l2.07-2.06a.56.56 0 0 1 .8.8l-1.11 1.1h2.77a.56.56 0 1 1 0 1.12H3.42l1.1 1.1ZM13.48 11.46a.56.56 0 0 1 0-.8l1.1-1.1h-2.77a.56.56 0 1 1 0-1.12h2.77l-1.1-1.1a.56.56 0 0 1 .8-.8l2.06 2.06a.56.56 0 0 1 0 .8l-2.07 2.06a.56.56 0 0 1-.8 0ZM11.46 13.48a.56.56 0 0 0-.8 0l-1.1 1.1v-2.77a.56.56 0 1 0-1.12 0v2.77l-1.1-1.1a.56.56 0 0 0-.8.8l2.06 2.06a.56.56 0 0 0 .8 0l2.06-2.07a.56.56 0 0 0 0-.8Z'/%3E%3C/svg%3E")
    no-repeat center;
  // border: solid #aaa 1px;
  // box-shadow: 0 2px 2px -2px;
  background-color: transparent;
  border: 0;
  cursor: move;
  // border-radius: 1px;
  z-index: 1;
  position: absolute;
  right: 3rem;
  margin-top: 0.75rem;
}

.rst__loadingHandle {
  cursor: default;
  background: #d9d9d9;
}

@keyframes pointFade {
  0%,
  19.999%,
  100% {
    opacity: 0;
  }
  20% {
    opacity: 1;
  }
}

.rst__loadingCircle {
  width: 80%;
  height: 80%;
  margin: 10%;
  position: relative;
}

.rst__loadingCirclePoint {
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
}

.rst__rtl.rst__loadingCirclePoint {
  right: 0;
  left: initial;
}

.rst__loadingCirclePoint::before {
  content: '';
  display: block;
  margin: 0 auto;
  width: 11%;
  height: 30%;
  background-color: #fff;
  border-radius: 30%;
  animation: pointFade 800ms infinite ease-in-out both;
}
.rst__loadingCirclePoint:nth-of-type(1) {
  transform: rotate(0deg);
}
.rst__loadingCirclePoint:nth-of-type(7) {
  transform: rotate(180deg);
}
.rst__loadingCirclePoint:nth-of-type(1)::before,
.rst__loadingCirclePoint:nth-of-type(7)::before {
  animation-delay: -800ms;
}
.rst__loadingCirclePoint:nth-of-type(2) {
  transform: rotate(30deg);
}
.rst__loadingCirclePoint:nth-of-type(8) {
  transform: rotate(210deg);
}
.rst__loadingCirclePoint:nth-of-type(2)::before,
.rst__loadingCirclePoint:nth-of-type(8)::before {
  animation-delay: -666ms;
}
.rst__loadingCirclePoint:nth-of-type(3) {
  transform: rotate(60deg);
}
.rst__loadingCirclePoint:nth-of-type(9) {
  transform: rotate(240deg);
}
.rst__loadingCirclePoint:nth-of-type(3)::before,
.rst__loadingCirclePoint:nth-of-type(9)::before {
  animation-delay: -533ms;
}
.rst__loadingCirclePoint:nth-of-type(4) {
  transform: rotate(90deg);
}
.rst__loadingCirclePoint:nth-of-type(10) {
  transform: rotate(270deg);
}
.rst__loadingCirclePoint:nth-of-type(4)::before,
.rst__loadingCirclePoint:nth-of-type(10)::before {
  animation-delay: -400ms;
}
.rst__loadingCirclePoint:nth-of-type(5) {
  transform: rotate(120deg);
}
.rst__loadingCirclePoint:nth-of-type(11) {
  transform: rotate(300deg);
}
.rst__loadingCirclePoint:nth-of-type(5)::before,
.rst__loadingCirclePoint:nth-of-type(11)::before {
  animation-delay: -266ms;
}
.rst__loadingCirclePoint:nth-of-type(6) {
  transform: rotate(150deg);
}
.rst__loadingCirclePoint:nth-of-type(12) {
  transform: rotate(330deg);
}
.rst__loadingCirclePoint:nth-of-type(6)::before,
.rst__loadingCirclePoint:nth-of-type(12)::before {
  animation-delay: -133ms;
}
.rst__loadingCirclePoint:nth-of-type(7) {
  transform: rotate(180deg);
}
.rst__loadingCirclePoint:nth-of-type(13) {
  transform: rotate(360deg);
}
.rst__loadingCirclePoint:nth-of-type(7)::before,
.rst__loadingCirclePoint:nth-of-type(13)::before {
  animation-delay: 0ms;
}

.rst__rowTitle {
  font-weight: bold;
}

.rst__rowTitleWithSubtitle {
  font-size: 85%;
  display: block;
  height: 0.8rem;
}

.rst__rowSubtitle {
  font-size: 70%;
  line-height: 1;
}

.rst__collapseButton,
.rst__expandButton {
  appearance: none;
  border: none;
  position: absolute;
  border-radius: 100%;
  box-shadow: 0 0 0 1px #000;
  width: 8px;
  height: 8px;
  padding: 0;
  top: 50%;
  transform: translate(-50%, -50%);
  cursor: pointer;
  display: none;
}
.rst__rtl.rst__collapseButton,
.rst__rtl.rst__expandButton {
  transform: translate(50%, -50%);
}
.rst__collapseButton:focus,
.rst__expandButton:focus {
  outline: none;
  box-shadow: 0 0 0 1px #000, 0 0 1px 3px #83bef9;
}
.rst__collapseButton:hover:not(:active),
.rst__expandButton:hover:not(:active) {
  background-size: 12px;
  height: 10px;
  width: 10px;
}

.rst__collapseButton {
  background: #fff
    url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxOCIgaGVpZ2h0PSIxOCI+PGNpcmNsZSBjeD0iOSIgY3k9IjkiIHI9IjgiIGZpbGw9IiNGRkYiLz48ZyBzdHJva2U9IiM5ODk4OTgiIHN0cm9rZS13aWR0aD0iMS45IiA+PHBhdGggZD0iTTQuNSA5aDkiLz48L2c+Cjwvc3ZnPg==')
    no-repeat center;
}

.rst__expandButton {
  background: #fff
    url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSIxOCIgaGVpZ2h0PSIxOCI+PGNpcmNsZSBjeD0iOSIgY3k9IjkiIHI9IjgiIGZpbGw9IiNGRkYiLz48ZyBzdHJva2U9IiM5ODk4OTgiIHN0cm9rZS13aWR0aD0iMS45IiA+PHBhdGggZD0iTTQuNSA5aDkiLz48cGF0aCBkPSJNOSA0LjV2OSIvPjwvZz4KPC9zdmc+')
    no-repeat center;
}

/**
 * Line for under a node with children
 */
.rst__lineChildren {
  height: 100%;
  display: inline-block;
  position: absolute;
}
.rst__lineChildren::after {
  content: '';
  position: absolute;
  background-color: black;
  width: 1px;
  left: 50%;
  bottom: 0;
  height: 10px;
}

.rst__rtl.rst__lineChildren::after {
  right: 50%;
  left: initial;
}

.rst__placeholder {
  position: relative;
  height: 68px;
  max-width: 300px;
  padding: 10px;
  display: none;
}
.rst__placeholder,
.rst__placeholder > * {
  box-sizing: border-box;
}
.rst__placeholder::before {
  border: 3px dashed #d9d9d9;
  content: '';
  position: absolute;
  top: 5px;
  right: 5px;
  bottom: 5px;
  left: 5px;
  z-index: -1;
}

/**
 * The outline of where the element will go if dropped, displayed while dragging
 */
.rst__placeholderLandingPad,
.rst__placeholderCancelPad {
  border: none !important;
  box-shadow: none !important;
  outline: none !important;
}
.rst__placeholderLandingPad *,
.rst__placeholderCancelPad * {
  opacity: 0 !important;
}
.rst__placeholderLandingPad::before,
.rst__placeholderCancelPad::before {
  background-color: lightblue;
  border-color: white;
}

/**
 * Alternate appearance of the landing pad when the dragged location is invalid
 */
.rst__placeholderCancelPad::before {
  background-color: #e6a8ad;
}

/**
 * Extra class applied to VirtualScroll through className prop
 */
.rst__virtualScrollOverride {
  overflow: hidden !important;
}
.rst__virtualScrollOverride * {
  box-sizing: border-box;
}

.ReactVirtualized__Grid__innerScrollContainer {
  overflow: visible !important;
}

.rst__rtl .ReactVirtualized__Grid__innerScrollContainer {
  direction: rtl;
}

.ReactVirtualized__Grid {
  outline: none;
}

.rst__node {
  min-width: 100%;
  white-space: nowrap;
  position: relative;
  text-align: left;
}

.rst__node.rst__rtl {
  text-align: right;
}

.rst__nodeContent {
  position: absolute;
  top: 0;
  bottom: 0;
}

/* ==========================================================================
   Scaffold
    Line-overlaid blocks used for showing the tree structure
   ========================================================================== */
.rst__lineBlock,
.rst__absoluteLineBlock {
  height: 100%;
  position: relative;
  display: inline-block;
}

.rst__absoluteLineBlock {
  position: absolute;
  top: 0;
}

.rst__lineHalfHorizontalRight::before,
.rst__lineFullVertical::after,
.rst__lineHalfVerticalTop::after,
.rst__lineHalfVerticalBottom::after {
  position: absolute;
  content: '';
  background-color: black;
}

/**
 * +-----+
 * |     |
 * |  +--+
 * |     |
 * +-----+
 */
.rst__lineHalfHorizontalRight::before {
  height: 1px;
  top: 50%;
  right: 0;
  width: 50%;
}

.rst__rtl.rst__lineHalfHorizontalRight::before {
  left: 0;
  right: initial;
}

/**
 * +--+--+
 * |  |  |
 * |  |  |
 * |  |  |
 * +--+--+
 */
.rst__lineFullVertical::after,
.rst__lineHalfVerticalTop::after,
.rst__lineHalfVerticalBottom::after {
  width: 1px;
  left: 50%;
  top: 0;
  height: 100%;
}

/**
 * +--+--+
 * |  |  |
 * |  |  |
 * |  |  |
 * +--+--+
 */
.rst__rtl.rst__lineFullVertical::after,
.rst__rtl.rst__lineHalfVerticalTop::after,
.rst__rtl.rst__lineHalfVerticalBottom::after {
  right: 50%;
  left: initial;
}

/**
 * +-----+
 * |  |  |
 * |  +  |
 * |     |
 * +-----+
 */
.rst__lineHalfVerticalTop::after {
  height: 50%;
}

/**
 * +-----+
 * |     |
 * |  +  |
 * |  |  |
 * +-----+
 */
.rst__lineHalfVerticalBottom::after {
  top: auto;
  bottom: 0;
  height: 50%;
}

/* Highlight line for pointing to dragged row destination
   ========================================================================== */
/**
 * +--+--+
 * |  |  |
 * |  |  |
 * |  |  |
 * +--+--+
 */
.rst__highlightLineVertical {
  z-index: 3;
}
.rst__highlightLineVertical::before {
  position: absolute;
  content: '';
  background-color: #36c2f6;
  width: 8px;
  margin-left: -4px;
  left: 50%;
  top: 0;
  height: 100%;
}

.rst__rtl.rst__highlightLineVertical::before {
  margin-left: initial;
  margin-right: -4px;
  left: initial;
  right: 50%;
}

@keyframes arrow-pulse {
  0% {
    transform: translate(0, 0);
    opacity: 0;
  }
  30% {
    transform: translate(0, 300%);
    opacity: 1;
  }
  70% {
    transform: translate(0, 700%);
    opacity: 1;
  }
  100% {
    transform: translate(0, 1000%);
    opacity: 0;
  }
}
.rst__highlightLineVertical::after {
  content: '';
  position: absolute;
  height: 0;
  margin-left: -4px;
  left: 50%;
  top: 0;
  border-left: 4px solid transparent;
  border-right: 4px solid transparent;
  border-top: 4px solid white;
  animation: arrow-pulse 1s infinite linear both;
}

.rst__rtl.rst__highlightLineVertical::after {
  margin-left: initial;
  margin-right: -4px;
  right: 50%;
  left: initial;
}

/**
 * +-----+
 * |     |
 * |  +--+
 * |  |  |
 * +--+--+
 */
.rst__highlightTopLeftCorner::before {
  z-index: 3;
  content: '';
  position: absolute;
  border-top: solid 8px #36c2f6;
  border-left: solid 8px #36c2f6;
  box-sizing: border-box;
  height: calc(50% + 4px);
  top: 50%;
  margin-top: -4px;
  right: 0;
  width: calc(50% + 4px);
}

.rst__rtl.rst__highlightTopLeftCorner::before {
  border-right: solid 8px #36c2f6;
  border-left: none;
  left: 0;
  right: initial;
}

/**
 * +--+--+
 * |  |  |
 * |  |  |
 * |  +->|
 * +-----+
 */
.rst__highlightBottomLeftCorner {
  z-index: 3;
}
.rst__highlightBottomLeftCorner::before {
  content: '';
  position: absolute;
  border-bottom: solid 8px #36c2f6;
  border-left: solid 8px #36c2f6;
  box-sizing: border-box;
  height: calc(100% + 4px);
  top: 0;
  right: 12px;
  width: calc(50% - 8px);
}

.rst__rtl.rst__highlightBottomLeftCorner::before {
  border-right: solid 8px #36c2f6;
  border-left: none;
  left: 12px;
  right: initial;
}

.rst__highlightBottomLeftCorner::after {
  content: '';
  position: absolute;
  height: 0;
  right: 0;
  top: 100%;
  margin-top: -12px;
  border-top: 12px solid transparent;
  border-bottom: 12px solid transparent;
  border-left: 12px solid #36c2f6;
}

.rst__rtl.rst__highlightBottomLeftCorner::after {
  left: 0;
  right: initial;
  border-right: 12px solid #36c2f6;
  border-left: none;
}

.tree-row {
  display: flex;
}

.tree-carousel {
  overflow: hidden;
}

.tree-carousel {
  width: calc(100vw - (4.375rem + 4rem + 20.5rem + 4rem + 17px));
  // width: calc(100vw - (32.875rem + 17px));
}

.jss4 .tree-carousel,
.jss12 .tree-carousel,
.makeStyles-contentShift-4 .tree-carousel,
.makeStyles-contentShift-12 .tree-carousel {
  width: calc(100vw - (19.375rem + 4rem + 20.5rem + 4rem + 17px));
  // width: calc(100vw - (47.875rem + 17px));
}
.percentage_in_final_product {
  .bg-white {
    padding-left: 0;
    padding-right: 0;
  }
}
.percentage_in_final_product.disabled {
  .bg-white {
    position: relative;
    &::after {
      content: '';
      background: #c4c4c4 !important;
      position: absolute;
      left: -0.5rem;
      top: 0;
      width: 70px;
      height: 100%;
      z-index: 10;
    }
  }
}

.SortableTree-column {
  text-align: left;
  padding-left: 0.25rem;
  padding-right: 0.25rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  display: flex;
  align-items: center;
  &.disabled .bg-white {
    background: #c4c4c4 !important;
    color: #c4c4c4 !important;
    border: solid 0.5rem #fff !important;
    width: 100%;
    min-height: 40px;
    * {
      display: none;
    }
  }
  input {
    width: 100%;
    background: #f9f9f9;
    height: 24px;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
    outline: none !important;
  }
}

.first-column {
  width: 19.5rem;
  min-width: 19.5rem;
  padding-left: 0.5rem;
}

.Components {
  width: 100px;
  min-width: 100px;
}
.ComponentsOfComponents {
  width: 220px;
  min-width: 220px;
}
.qty_in_mixing_bowl {
  width: 100px;
  min-width: 100px;
}
.qty_in_final_product {
  width: 111px;
  min-width: 111px;
}
.percentage_in_final_product {
  width: 50px;
  min-width: 50px;
}
.percentage_in_final_product_manual {
  width: 85px;
  min-width: 85px;
}
.SortableTree-header .percentage_in_final_product_manual > span {
  width: 129px;
  margin-left: -50px;
}
.GradeOrState {
  width: 110px;
  min-width: 110px;
}
.e_number {
  width: 140px;
  min-width: 140px;
}
.function_of_additives {
  width: 180px;
  min-width: 180px;
}
.gmo_status {
  width: 185px;
  min-width: 185px;
}
.country_of_origin {
  width: 450px;
  min-width: 450px;
}
.SupplierName {
  width: 320px;
  min-width: 320px;
}
.quid {
  width: 40px;
  min-width: 40px;
}
.declared {
  width: 95px;
  min-width: 95px;
}
.Group {
  width: 100px;
  min-width: 100px;
}
.comments_and_extra_information {
  width: 250px;
  min-width: 250px;
}

.rst__nodeContent {
  right: 0;
}

.tree-carousel-wrap {
  display: flex;
  flex-wrap: nowrap;
}

.SortableTree-parent {
  // overflow: hidden;
  width: calc(100% + 3rem);
  // height: 1200px;
  // padding-right: 100px;
  margin-left: -1rem;
  font-size: 14px;
  position: relative;
}
.country_of_origin {
  // .SortableTree-column input {
  //   background-color: transparent;
  // }
  // .MuiAutocomplete-tag {
  //   margin: 1px;
  // }
  // .MuiChip-deleteIcon {
  //   width: 16px;
  //   height: 16px;
  // }
  // .MuiAutocomplete-input {
  //   padding: 1px 4px;
  // }
  // .MuiChip-root {
  //   font-size: 0.75rem;
  //   height: 20px;
  // }
  // .MuiFormControl-root {
  //   height: 24px;
  //   background: #f9f9f9;
  // }
  // .MuiAutocomplete-inputRoot {
  //   background-color: transparent !important;
  //   height: 40px;
  //   overflow-y: auto;
  //   overflow-x: hidden;
  // }
  // .MuiChip-label {
  //   padding-left: 6px;
  //   padding-right: 6px;
  // }
}
.rst__row {
  padding-right: 2rem;
}
.rst__node {
  height: 44px;
}

// .contract-trigger{
//   width: 1000px !important;
// }

// .resize-triggers{
//   width: 1000px !important;
// }
// .expand-trigger{
//   width: 1000px !important;
// }

// .rst__tree{
//   width: 1000px !important;
// }

.jss4,
.jss12,
.makeStyles-contentShift-4,
.makeStyles-contentShift-12 {
  .scrollBar {
    width: calc(100vw - (19.375rem + 4rem + 20.5rem + 4rem + 17px + 6.25rem));
    // width: calc(100vw - (54.125rem + 17px));
  }
}

.scrollBar {
  left: 19.5rem;
  position: relative;
  height: 2px;
  width: calc(100vw - (4.375rem + 4rem + 20.5rem + 4rem + 17px + 6.25rem));
  &::after {
    content: '';
    background: #c4c4c4;
    height: 2px;
    position: absolute;
    left: 0;
    top: 0;
    width: calc(100% + 6.25rem);
  }
}
.scrollBar-trigger {
  width: 6.25rem;
  border-radius: 10px;
  background: #e45572;
  left: 0;
  transform: translateY(-50%);
  height: 10px;
  cursor: pointer;
  position: absolute;
  z-index: 10;
}
.tree-row-remove {
  position: absolute;
  right: 2rem;
  cursor: pointer;
  height: 24px;
  margin-top: 1.4rem;
  background: #fff;
  padding-right: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  .btn {
    width: 18px;
    height: 18px;
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='18' height='18' fill='none'%3E%3Cpath fill='%23686868' fill-rule='evenodd' d='M11.63 3h2.62v1.5H3.75V3h2.63l.75-.75h3.75l.74.75ZM6 15.75a1.5 1.5 0 0 1-1.5-1.5v-9h9v9c0 .82-.68 1.5-1.5 1.5H6Z' clip-rule='evenodd'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-color: #fff !important;
    background-position: center !important;
  }
}

.SortableTree-footer {
  display: flex;
  margin-bottom: 32px;
  .input {
    background: #fff;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
  }
  .last-column {
    position: relative;
    button {
      position: absolute;
      right: -16px;
      white-space: nowrap;
      top: 50%;
      transform: translateY(-50%);
      background: #f5f6fa;
    }
  }
}
.SortableTree-end {
  position: absolute;
  bottom: 5px;
  left: 1rem;
}

.rst__node > .rst__lineBlock:first-child {
  opacity: 0;
}
.SortableTree-header {
  font-size: 14px;
  display: flex;
  background: #e3e5e8;
  width: calc(100% - 3rem);
  margin-left: 16px;
  .SortableTree-column {
    height: 50px;
    padding-top: 5px;
    padding-bottom: 5px;
  }
}
.first-column {
  font-size: 14px;
}
.first-column input {
  width: 100%;
  height: 24px;
}
.last-column {
  width: 64px;
}

.rst__nodeContent[style='left: 16px;'] .first-column {
  width: calc(20.5rem - 1rem);
  min-width: calc(20.5rem - 1rem);
}

.rst__nodeContent[style='left: 32px;'] .first-column {
  width: calc(20.5rem - 2rem);
  min-width: calc(20.5rem - 2rem);
}

.rst__nodeContent[style='left: 48px;'] .first-column {
  width: calc(20.5rem - 3rem);
  min-width: calc(20.5rem - 3rem);
}

.rst__nodeContent[style='left: 64px;'] .first-column {
  width: calc(20.5rem - 4rem);
  min-width: calc(20.5rem - 4rem);
}

.rst__nodeContent[style='left: 80px;'] .first-column {
  width: calc(20.5rem - 5rem);
  min-width: calc(20.5rem - 5rem);
}

.rst__nodeContent[style='left: 96px;'] .first-column {
  width: calc(20.5rem - 6rem);
  min-width: calc(20.5rem - 6rem);
}

.tabs {
  display: flex;
  position: relative;
  z-index: 11;
  .tab {
    display: flex;
    padding: 0 0.5rem;
    align-items: center;
    cursor: pointer;
    background: #e3e5e8;
    border-radius: 0;
    opacity: 0.5;
    height: 30px;
    &:hover {
      background: #e3e5e8;
    }
    &.details {
      opacity: 1;
      background: #16233c;
      cursor: auto;
      border-bottom: solid 1px #162641;
      svg,
      .item {
        color: #fff;
      }

      .name {
        font-size: 20px;
      }
      .details-item {
        display: block;
      }
    }
  }
  .details-item {
    display: none;
    border-left: solid 1px #c4c4c4;
  }
  .item {
    color: #162641;
    padding-left: 10px;
    padding-right: 10px;
  }
  .details-item {
  }
}
// ---------------------------------
//    End Ingredient table
// ---------------------------------

.suggest {
  position: relative;
  width: 100%;
}

.suggest__list {
  position: absolute;
  left: -0.5rem;
  top: 24px;
  z-index: 100;
  background: #fff;
  max-height: 200px;
  overflow: auto;
  display: block;
  width: calc(100% + 1rem);
  // visibility: hidden;
  transition-delay: 0.01s;
  padding: 2px 1rem;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  text-align: left;

}

.suggest__list:focus {
  outline: none !important;
}
.suggest__word {
  z-index: 0;
  color: #000;
  font-weight: normal;
  display: block;
  cursor: pointer;
  padding: 5px 0;
  text-align: left;
  &:hover {
    background: rgba(0, 0, 0, 0.1);
  }
}
.suggest__input {
  // background: transparent !important;
  z-index: 1;
  position: relative;
  width: 100%;
  &:focus {
    + .suggest__list {
      visibility: visible;
      transition-delay: 0s;
    }
  }
}

.inValid {
  .empty.required {
    input {
      border: solid 1px #ef5350 !important;
    }
  }
}

.e_number {
  .warning {
    border: solid 4px #ffd34c !important;
    margin-left: -4px;
  }
}
.GM {
  .Yes .flex.flex-row {
    border: solid 4px #ffd34c !important;
    margin-left: -4px;
  }
}

// .inValid .tabs .inValidLayer .tab {
//   background: #ef5350 !important;
// }

// .switch-error button {
//   outline: solid 1px red;
// }

.min-w-3\/12 {
  min-width: 25% !important;
}

.max-w-3\/12 {
  max-width: 25% !important;
}

.row-title {
  min-width: 20%;
  max-width: 20%;
}

.amber-width {
  max-width: 20%;
  min-width: 20%;
}
.red-width,
.green-width {
  max-width: 27.5%;
  min-width: 27.5%;
}

.qas-remove {
  max-width: 5%;
  min-width: 5%;
  text-align: right;
  justify-content: end;
}

.inValid .photo_standards-hasError .photo_standards_item:first-child {
  .empty input {
    border: solid 1px #ef5350 !important;
  }
  .empty label {
    border: solid 1px #ef5350 !important;
    background-color: #ef5350;
    border-radius: 4px;
  }
}

.text-ellipsis {
  text-overflow: ellipsis !important;
}

.nuts-contains-false [class^='contains-substances.0.sub_rows'] {
  display: none;
}

.nuts-may_contain-false [class^='may_contain-substances.0.sub_rows'] {
  display: none;
}

.nuts-on_same_manufacturing_area-false
  [class^='on_same_manufacturing_area-substances.0.sub_rows'] {
  display: none;
}

.nuts-on_same_production_line-false
  [class^='on_same_production_line-substances.0.sub_rows'] {
  display: none;
}
.glutent-contains-false [class^='contains-substances.1.sub_rows'] {
  display: none;
}

.glutent-may_contain-false [class^='may_contain-substances.1.sub_rows'] {
  display: none;
}

.glutent-on_same_manufacturing_area-false
  [class^='on_same_manufacturing_area-substances.1.sub_rows'] {
  display: none;
}

.glutent-on_same_production_line-false
  [class^='on_same_production_line-substances.1.sub_rows'] {
  display: none;
}

.isAverageWeightControlChosen-false td:nth-last-child(-n + 2) {
  visibility: hidden;
  opacity: 0;
}

.error-border {
  background-color: #ef5350 !important;
}



.inValid .loaded_pallet_assembled-true  .tabStatus-false button#simple-tab-2 .MuiTouchRipple-root{
  background-color: rgba(239, 83, 80,0.5);
}

.inValid .pallet_details-true  .tabStatus-false button#simple-tab-1 .MuiTouchRipple-root{

  background-color: rgba(239, 83, 80,0.5);;
}


.inValid .pallet_general_info-true .tabStatus-false button#simple-tab-0 .MuiTouchRipple-root{
  background-color: rgba(239, 83, 80,0.5);;
}


.wide{
  button{
    min-width: 90px !important;
    padding: 8px 16px !important;
    height: auto !important;
  } 
}

.last-item-center:last-child{
  text-align: center;
}